
<template>
    <MAINCATEGORYSItem />
</template>

<script>
import MAINCATEGORYSItem from '../components/MAINCATEGORYSItem.vue'

export default {
  components: {
    MAINCATEGORYSItem
  }
}
</script>